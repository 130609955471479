import React from 'react';
import { Home } from "./pages";
import './App.css';

const App = () => {
  return (
    <Home />
  );
}

export default App;
